// Function to change the text of the first option
function changeFirstOptionText(selectElements) {
  if (!selectElements || selectElements.length === 0) return;
  
  for (let i = 0; i < selectElements.length; i++) {
    const select = selectElements[i];
    if (select && select.options && select.options.length > 0) {
      select.options[0].text = "Subscribe & Save";
      select.options[0].disabled = true;
    }
  }
}

// Debounce function to limit execution frequency
function debounce(func, wait) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      func.apply(context, args);
    }, wait);
  };
}

// Function to process changes
const processChanges = debounce(function() {
  const selectElements = document.querySelectorAll('body[data-test-plp-atc="true"] .product-item .test-plp-btns-reverse #product-item-recharge-select');
  changeFirstOptionText(selectElements);
}, 100); // 100ms debounce

document.addEventListener('DOMContentLoaded', function() {
  // Initial execution
  const selectElements = document.querySelectorAll('body[data-test-plp-atc="true"] .product-item .test-plp-btns-reverse #product-item-recharge-select');
  changeFirstOptionText(selectElements);
  
  // Find a more specific parent container to observe instead of the entire body
  const productContainer = document.querySelector('.product-item')?.closest('.grid') || document.body;
  
  // Options for the observer - only watch for specific types of changes
  const config = { 
    childList: true,
    subtree: true,
    attributes: false,
    characterData: false
  };
  
  // Create an observer instance with performance optimizations
  const observer = new MutationObserver(function(mutations) {
    let shouldProcess = false;
    
    // Only process if relevant elements might have changed
    for (let i = 0; i < mutations.length; i++) {
      const mutation = mutations[i];
      if (mutation.addedNodes.length > 0) {
        shouldProcess = true;
        break;
      }
    }
    
    if (shouldProcess) {
      processChanges();
    }
  });
  
  // Start observing
  observer.observe(productContainer, config);
  
  // Disconnect observer after 10 seconds if the page is fully loaded
  // This assumes the dynamic content loads within this timeframe
  setTimeout(function() {
    const finalSelectElements = document.querySelectorAll('body[data-test-plp-atc="true"] .product-item .test-plp-btns-reverse #product-item-recharge-select');
    changeFirstOptionText(finalSelectElements);
    
    // If we've already processed all elements, disconnect the observer
    if (finalSelectElements.length > 0) {
      observer.disconnect();
    }
  }, 10000);
});